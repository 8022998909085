import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Box, Input, Button, Section } from "@quarkly/widgets";
import QuarklycommunityKitNetlifyForm from "./QuarklycommunityKitNetlifyForm";
const defaultProps = {
	"padding": "88px 0 88px 0",
	"quarkly-title": "Form-7"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"margin": "0px 0px 40px 0px",
			"lg-padding": "0px 0px 0px 0px",
			"padding": "0px 170px 0px 170px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 30px 0px",
			"font": "--headline2",
			"color": "--darkL1",
			"sm-font": "--headline2",
			"sm-text-align": "left",
			"lg-padding": "0px 0px 0px 0px",
			"text-align": "center",
			"lg-max-width": "640px",
			"lg-text-align": "left",
			"children": <>
				İletişim formu{"\n"}
			</>
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"font": "--lead",
			"color": "--greyD2",
			"lg-padding": "0px 0px 0px 0px",
			"sm-text-align": "left",
			"text-align": "center",
			"lg-max-width": "640px",
			"lg-text-align": "left",
			"max-width": "640px",
			"children": "Danışma veya rezervasyon için iletişim bilgilerinizi bırakın, kısa süre içinde sizinle iletişime geçelim"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"padding": "40px 40px 60px 40px",
			"background": "--color-orange",
			"border-radius": "24px",
			"max-width": "940px",
			"width": "100%",
			"lg-max-width": "480px",
			"lg-padding": "40px 40px 40px 40px"
		}
	},
	"quarklycommunityKitNetlifyForm": {
		"kind": "QuarklycommunityKitNetlifyForm",
		"props": {}
	},
	"quarklycommunityKitNetlifyFormOverride": {
		"kind": "Override",
		"props": {
			"slot": "Form",
			"flex-direction": "row",
			"display": "flex",
			"width": "100%",
			"align-items": "flex-end",
			"justify-content": "center"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"align-items": "flex-end",
			"md-align-items": "center",
			"display": "flex",
			"justify-content": "center",
			"lg-flex-wrap": "wrap",
			"lg-justify-content": "flex-end"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"margin": "0px 30px 0px 0px",
			"md-width": "100%",
			"display": "flex",
			"flex-direction": "column",
			"sm-margin": "0px 0px 0px 0px",
			"lg-margin": "0px 0px 30px 0px",
			"md-margin": "0px 0px 30px 0px",
			"flex": "1 1 auto",
			"width": "100%"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 8px 0px",
			"color": "--light",
			"font": "--lead",
			"children": "İsim"
		}
	},
	"input": {
		"kind": "Input",
		"props": {
			"border-radius": "8px",
			"padding": "11px 16px 11px 16px",
			"required": false,
			"font": "--lead",
			"color": "rgba(111, 131, 159, 0.5)",
			"border-width": "0px",
			"placeholder-color": "rgba(111, 131, 159, 0.5)",
			"placeholder": "Mahir",
			"name": "Name",
			"type": "text",
			"sm-width": "100%",
			"lg-width": "100%",
			"lg-margin": "0px 0px 10px 0px",
			"flex": "1 1 auto",
			"width": "100%",
			"margin": "0px 0px 0px 0px"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"margin": "0px 30px 0px 0px",
			"sm-margin": "0px 0px 30px 0px",
			"lg-margin": "0px 0px 30px 0px",
			"md-width": "100%",
			"flex": "1 1 auto",
			"width": "100%",
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 8px 0px",
			"color": "--light",
			"font": "--lead",
			"children": <>
				E-posta{"\n"}
			</>
		}
	},
	"input1": {
		"kind": "Input",
		"props": {
			"color": "rgba(111, 131, 159, 0.5)",
			"lg-margin": "0px 0px 10px 0px",
			"border-width": "0px",
			"placeholder": "name@mail.com",
			"flex": "1 1 auto",
			"name": "Email",
			"required": false,
			"width": "100%",
			"padding": "11px 16px 11px 16px",
			"type": "email",
			"lg-width": "100%",
			"placeholder-color": "rgba(111, 131, 159, 0.5)",
			"margin": "0px 0px 0px 0px",
			"font": "--lead",
			"sm-width": "100%",
			"border-radius": "8px"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"margin": "0px 30px 0px 0px",
			"sm-margin": "0px 0px 30px 0px",
			"lg-margin": "0px 0px 30px 0px",
			"md-width": "100%",
			"flex": "1 1 auto",
			"width": "100%",
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 8px 0px",
			"color": "--light",
			"font": "--lead",
			"children": <>
				Telefon{"\n"}
			</>
		}
	},
	"input2": {
		"kind": "Input",
		"props": {
			"color": "rgba(111, 131, 159, 0.5)",
			"lg-margin": "0px 0px 10px 0px",
			"border-width": "0px",
			"placeholder": "+90 (5__) ___ __ __",
			"flex": "1 1 auto",
			"name": "Email",
			"required": false,
			"width": "100%",
			"padding": "11px 16px 11px 16px",
			"type": "tel",
			"lg-width": "100%",
			"placeholder-color": "rgba(111, 131, 159, 0.5)",
			"margin": "0px 0px 0px 0px",
			"font": "--lead",
			"sm-width": "100%",
			"border-radius": "8px"
		}
	},
	"button": {
		"kind": "Button",
		"props": {
			"font": "--lead",
			"padding": "12px 64px 12px 64px",
			"background": "--color-darkL2",
			"border-radius": "8px",
			"sm-display": "block",
			"sm-width": "100%"
		}
	}
};

const Contact = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" align-items="center" lg-align-items="flex-start" />
		<Box {...override("box")}>
			<Text {...override("text")} />
			<Text {...override("text1")} />
		</Box>
		<Box {...override("box1")}>
			<QuarklycommunityKitNetlifyForm {...override("quarklycommunityKitNetlifyForm")}>
				<Override {...override("quarklycommunityKitNetlifyFormOverride")} />
				<Box {...override("box2")}>
					<Box {...override("box3")}>
						<Text {...override("text2")} />
						<Input {...override("input")} />
					</Box>
					<Box {...override("box4")}>
						<Text {...override("text3")} />
						<Input {...override("input1")} />
					</Box>
					<Box {...override("box5")}>
						<Text {...override("text4")} />
						<Input {...override("input2")} />
					</Box>
					<Button {...override("button")}>
						Gönder
					</Button>
				</Box>
			</QuarklycommunityKitNetlifyForm>
		</Box>
		{children}
	</Section>;
};

Object.assign(Contact, { ...Section,
	defaultProps,
	overrides
});
export default Contact;