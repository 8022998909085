import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Em, Link, Box, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "80px 0 80px 0",
	"background": "--color-darkL2"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"align-items": "center",
			"flex-direction": "column",
			"justify-content": "center",
			"padding": "0px 200px 0px 200px",
			"lg-padding": "0px 0px 0px 0px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 30px 0px",
			"color": "--light",
			"text-align": "center",
			"sm-font": "normal 700 62px/1.2 \"Source Sans Pro\", sans-serif",
			"font": "--headline3",
			"children": "Etkinlik Vizyonunuzu Gerçeğe Dönüştürün!"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 48px 0px",
			"font": "normal 600 24px/1.5 --fontFamily-serifTimes",
			"color": "--light",
			"text-align": "center",
			"children": "Olağanüstü bir etkinlik planlamaya hazır mısınız? Bugün Eclipse Events ile iletişime geçin ve vizyonunuzu hayata geçirelim. Ekibimizle iletişime geçmek ve unutulmaz bir etkinliğe doğru yolculuğunuza başlamak için aşağıdaki düğmeye tıklayın."
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"text-align": "center",
			"color": "--light",
			"font": "--headline3",
			"text-decoration-line": "initial",
			"margin": "0px 0px 16px 0px",
			"href": "info",
			"display": "none",
			"children": "info@"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"href": "tel:1234567899",
			"text-align": "center",
			"color": "--light",
			"font": "--headline3",
			"text-decoration-line": "initial",
			"margin": "0px 0px 16px 0px",
			"children": "+902123173300"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 48px 0px",
			"font": "--headline3",
			"color": "--light",
			"text-align": "center",
			"children": "Levent, Gazeteci Ümit Deniz Sokağı No:6, 34330 Beşiktaş/İstanbul, Türkiye"
		}
	}
};

const NewFooter = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")}>
			<Text {...override("text")} />
			<Text {...override("text1")} />
			<Link {...override("link")} />
			<Link {...override("link1")} />
			<Text {...override("text2")} />
		</Box>
		{children}
	</Section>;
};

Object.assign(NewFooter, { ...Section,
	defaultProps,
	overrides
});
export default NewFooter;